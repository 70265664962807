import * as React from 'react';
import styled from '@emotion/styled';

import {BackgroundAPIImage, OverlayModal} from '../global';
import {Row, Column, Box} from '../layout';
import {Text} from '../typography';
import {Button} from '../forms';
import {useModal} from '../../hooks/useModal';
import {useQueryParam} from '../../hooks/useQueryParam';

export const ThankYouModal: React.FC = () => {
  const [trial, setTrial] = React.useState<number>(0);
  const {close, open} = useModal('thankYou');
  const [ty, setTy] = useQueryParam('ty');

  React.useEffect(() => {
    if (ty) {
      if (ty !== 'true') {
        setTrial(Number(ty));
      }
      open();
      setTy(undefined);
    }
  }, [ty]);

  return (
    <OverlayModal name="thankYou" padding={0} closeButton>
      <Box maxWidth="620px">
        <Box height="237px" position="relative">
          <BackgroundAPIImage src="/images/plus-upgrade.png" doNotResize />
        </Box>
        <Box padding={40}>
          <Row>
            <Column spacing={60} textAlign={'center'}>
              <Title>{'Thank you for joining us'}</Title>
              <Text spacing={10}>
                {trial ?
                  `You can now access the full library for ${trial} days.` :
                  'Dive into the full library and enjoy all it has to offer!'}
              </Text>
            </Column>
            <Column justify="center">
              <Button
                id="thankYouCloseButton"
                type="button"
                onClick={() => {
                  close();
                }}
              >
                Get Started
              </Button>
            </Column>
          </Row>
        </Box>
      </Box>
    </OverlayModal>
  );
};

export const Title = styled.p(
    ({theme}) => `
  font-size: 32px;
  font-weight: ${theme.fontWeights.bold};
  margin-bottom: 5px;
`,
);
